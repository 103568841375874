import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import { required } from 'vuelidate/lib/validators';
import addHrLocation from './addHrLocation';
export default {
  name: 'addEditCreatefaLocation',
  components: {
    appStrings,
    DatePicker,
    addHrLocation
  },
  props: ['getFaLocation'],
  data() {
    return {
      unsubscribe: null,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      batchNo: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      hrData: {
        location: { value: null, text: null },
        country: null,
        state: null,
        city: null,
        pincode: null,
        type: { value: 'T', text: 'Temporary' },
        nature: { value: null, text: null },
        lob: { value: null, text: null },
        inactive_date: null,
        address: null,
        address_id: null,
        hrDataItem: [
          {
            location_status: 'ACTIVE',
            location_status_name: 'ACTIVE',
            location_name: null,
            deboarding_date: null,
            fa_location_id: 0
          }
        ]
      },
      hrDataField: [
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'location_name',
        },
        {
          key: 'location_status_name',
          label: 'Location Status'
        },
        {
          key: 'deboarding_date'
        }
      ],
      showAddHrLocationModal: false,
      specIndex: null,
      accessButtons: {}
    };
  },
  validations: {
    hrData: {
      location: {
        value: { required }
      }
    }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && !this.showAddHrLocationModal) {
          this.addEditCreatefaLocation();
        }
      }
    });
  },
  methods: {


    showHideAddHrLocationModal(flag) {
      this.showAddHrLocationModal = flag;
      if (flag) {
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: false,
          addAllow: true,
          printAllow: false,
          approvalAllow: false
        };
      }
    },

    addEditCreatefaLocation() {
      var count=0
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const fa_location_list = this.hrData.hrDataItem.map(item => {
          if(item.location_name==null || item.location_name==""){
            count++
          }
          return {
            fa_location_id: item.fa_location_id,
            deboarding_date: item.deboarding_date,
            location_status: item.location_status,
            org_location_id: this.hrData.location.value,
            segment2: item.location_name
          };
        });
        const payload = { fa_location_list: fa_location_list };
        if(count>0){
          this.$bvToast.toast("Location name is mandatory", {
            title: 'Alert',
            variant: 'danger',
            solid: true
          });
        }else{
        this.loader = true;
        this.$store
          .dispatch('assets/addFaLocation', payload)
          .then(resp => {
            this.loader = false;
            this.showAlert = true;

            if (resp.status === 201) {
              this.isSuccess = true;
              this.responseMsg = resp.data.message;
              const dats = resp.data.data.slice(
                resp.data.data.length - payload.fa_location_list.length
              );
              let num = dats[0].fa_location_id;
              for (let i = 0; i < this.hrData.hrDataItem.length; i++) {
                this.hrData.hrDataItem[i].fa_location_id = num;
                num++;
              }
              this.getFaLocation();
            } else if (resp.response.status === 500) {
              this.isSuccess = false;
              this.responseMsg = resp.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
        }
      }
    },
    addNewRow() {
      this.hrData.hrDataItem.push({
        location_status: 'ACTIVE',
        location_status_name: 'ACTIVE',
        location_name: null,
        deboarding_date: null,
        org_location_id: this.hrData.location.value,
        fa_location_id: 0
      });
    },

    removeRow(index) {
      this.hrData.hrDataItem.splice(index, 1);
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      this.parent_value_set_id = null;
      // if (this.vsetCode === appStrings.VALUESETTYPE.LOCATION) {
      //   this.parent_value_set_id = this.hrData.location.value;
      // }
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.HR_LOCATION) {
        this.hrData.location.text = item.value_code;
        this.hrData.location.value = item.value_set_dtl_id;
        this.hrData.country = item.country;
        this.hrData.state = item.state;
        this.hrData.city = item.city;
        this.hrData.pincode = item.pincode;
        this.hrData.inactive_date = item.inactive_date;
        this.hrData.lob.text = item.lob_meaning;
        this.hrData.lob.value = item.lob_vset;
        this.hrData.type.text = item.location_type_meaning;
        this.hrData.type.value = item.location_type;
        this.hrData.nature.text = item.location_nature_meaning;
        this.hrData.nature.value = item.location_nature_vset;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FA_LOCATION_STATUS) {
        this.hrData.hrDataItem[this.specIndex].location_status =
          item.value_code;
        this.hrData.hrDataItem[this.specIndex].location_status_name =
          item.value_meaning;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.hrData.location.value) {
        this.hrData.location.text = null;
        this.hrData.location.value = null;
        this.hrData.country = null;
        this.hrData.state = null;
        this.hrData.city = null;
        this.hrData.pincode = null;
        this.hrData.inactive_date = null;
        this.hrData.lob.text = null;
        this.hrData.lob.value = null;
        this.hrData.type.text = null;
        this.hrData.type.value = null;
        this.hrData.nature.text = null;
        this.hrData.nature.value = null;
      }
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
