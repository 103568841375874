import appStrings from '@/app/utility/string.utility';

export default {
  name: 'addLocator',
  components: {appStrings},
  props:['faId'],
  data() {
    return {
      totalRows: null,
      currentPage: 1,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      showAddFaLocationModal: false,
      showValueSetModal:false,
      showOpenModal: false,
      locatorData: [],
      locatorFields: [
        {
          key: 'add',
          stickyColumn: true
        },
        { 
          key: 'remove', 
          stickyColumn: true 
        },
        {
          key: 'locator_code',
        },
        {
          key: 'locator_name',
        },
        {
          key: 'status',
        }
      ],
      specIndex:null,
      editMode:false,
    };
  },
  mounted() {
    this.postLocators();
    setTimeout(() => {
      this.getLocators();
    }, 150);
    
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
         if(actionName==='update' || actionName === 'edit'){
          this.editMode=true;
        }
        else if (actionName === 'save' ) {
          this.updateLocator();
        }
      }
    });
  },
  methods: {
    getLocators(){
      const payload1 = {
        fa_location_id: this.faId,
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getLocator', payload1)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.locatorData = resp.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    updateLocator(){
      const payload=[];
      this.locatorData.map(item=>{
          payload.push({
            fa_location_id: item.fa_location_id,
            locator_code:item.locator_code,
            locator_id:item.locator_id,
            locator_name:item.locator_name,
            status:item.status
          })
       });
      this.loader = true;
      this.$store
        .dispatch('assets/addLocator', payload)
        .then(resp => {
          this.loader = false;
          this.showAlert = true;
          if (resp.status === 201) {
            this.editMode=false
            this.isSuccess = true;
            this.getLocators()
            this.responseMsg=resp.data.message
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addNewRow() {
      if(this.editMode){
        this.locatorData.push({
          locator_name: null,
          locator_code:null,
          status:"ACTIVE",
          locator_id:0,
          fa_location_id:this.faId,
        });
      }
    },

    removeRow(index) {
      if (this.editMode) {
      this.locatorData.splice(index, 1);
      }
    },

    openValueSetModal(vsetCode,index) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.specIndex=index;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if(this.vsetCode===appStrings.VALUESETTYPE.STATUS_VSET){
        this.locatorData[this.specIndex].status= item.value_code
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode,index) {
      this.specIndex=index;
      this.vsetCode=vsetCode
      if(this.vsetCode===appStrings.VALUESETTYPE.STATUS_VSET){
        this.locatorData[this.specIndex].status= null,
        this.locatorData[this.specIndex].locator_id= null
      } 
    },

    postLocators(){
      const payload=[];
          payload.push({
            fa_location_id:this.faId,
            locator_code:null,
            locator_id:0,
            locator_name:null,
            status:null,
          });
      this.loader = true;
      this.$store
        .dispatch('assets/addLocator', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 201) {
            this.loader = false;          
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
