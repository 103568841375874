import commonHelper from '@/app/utility/common.helper.utility';
import addEditCreatefaLocation from './addEditCreatefaLocation';
import appStrings from '@/app/utility/string.utility';
import addLocator from './addLocator';
import DatePicker from 'vue2-datepicker';
import rackDetails from '../assetMasterLocators/rackDetails';

export default {
  name: 'faLocation',
  components: {
    addEditCreatefaLocation,
    addLocator,
    appStrings,
    DatePicker,
    rackDetails
  },
  watch: {
    currentPage: function() {
      this.getFaLocation();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getFaLocation();
    }
  },
  data() {
    return {
      totalRows: null,
      currentPage: 1,
      loader: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOptionSelectedInvoice(),
      showAddFaLocationModal: false,
      faLocation: {
        text: null,
        value: null
      },
      editMode: false,
      hrLocation: {
        text: null,
        value: null
      },
      showOpenModal: false,
      responseMsg: '',
      isSuccess: false,
      faLocationData: [],
      faLocationFields: [
        {
          key: 'fa_location_name',
          label: 'FA Location'
        },
        {
          key: 'address',
          label: 'Address'
        },
        {
          key: 'location_type_meaning',
          label: 'Type'
        },
        {
          key: 'location_nature_vset_meaning',
          label: 'Location/Nature'
        },
        {
          key: 'lob_vset_meaning',
          label: 'LOB'
        },
        {
          key: 'location_status_meaning',
          label: 'Location Status'
        },
        {
          key: 'inactive_date',
          label: 'Inactive Date'
        },
        {
          key: 'deboarding_date',
          label: 'Deboarding Date'
        },
        {
          key: 'org_location_name',
          label: 'HR Location'
        },
        {
          key: 'add_locator',
          label: 'Add Locator',
          class:'d-none'
        }
      ],
      specIndex: null,
      showAlert: false,
      showValueSetModal: false,
      active: true,
      faId: null,
      payload: null,
      accessButtons: {},
      showRackModal: false,
      rackRowData: null,
      parent_value_set_id: null
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add' && !this.showOpenModal) {
          this.showHideAddFaLocationModal(true);
        } else if (actionName === 'update' || actionName === 'edit') {
          this.editMode = true;
        } else if (
          actionName === 'save' &&
          !this.showAddFaLocationModal &&
          !this.showOpenModal
        ) {
          this.addEditLocation();
        } else if (
          actionName === 'download' &&
          !this.showOpenModal &&
          !this.showAddFaLocationModal
        ) {
          this.loader = true;
          const downloadpayload = { ...this.payload };
          // downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'assets/getFaLocation',
            'fa-locations',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    showHideAddFaLocationModal(flag) {
      this.showAddFaLocationModal = flag;
      if (flag) {
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: false,
          addAllow: true,
          printAllow: false,
          approvalAllow: false
        };
      }
    },
    showHideOpenModal(flag, faId) {
      this.showOpenModal = flag;
      this.faId = faId;
      if (flag) {
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: true,
          addAllow: true,
          printAllow: false,
          approvalAllow: false
        };
      }
    },
    getFaLocation() {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        fa_location: this.faLocation.value,
        org_location: this.hrLocation.value,
        fa_location_type: this.location_type_meaning,
        mapped: this.active
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getFaLocation', this.payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.faLocationData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditLocation() {
      const payload1 = [];
      this.faLocationData.map(item => {
        if (item.org_location_id !== null) {
          payload1.push({
            deboarding_date: item.deboarding_date,
            org_location_id: item.org_location_id,
            fa_location_id: item.fa_location_id
          });
        }
      });
      this.loader = true;
      this.$store
        .dispatch('assets/addEditHrLocation', payload1)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.showAlert = true;
            (this.isSuccess = true), (this.responseMsg = resp.data.message);
            this.editMode = false;
            this.totalRows = resp.data.data.total_elements;
            this. getFaLocation();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.specIndex = index;
      this.parent_value_set_id = null;
      if(vsetCode === appStrings.VALUESETTYPE.ASSET_LOCATION) {
        this.parent_value_set_id = this.hrLocation.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.ASSET_LOCATION) {
        (this.faLocation.text = item.value_code),
          (this.faLocation.value = item.value_set_dtl_id);
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LOCATION) {
        if (this.specIndex === null) {
          this.hrLocation.text = item.loc_name;
          this.hrLocation.value = item.loc_id;
        } else {
          this.faLocationData[this.specIndex].org_location_name = item.loc_name;
          this.faLocationData[this.specIndex].org_location_id =
            item.loc_id;
        }
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode, index) {
      this.specIndex = index;
      this.vsetCode = vsetCode;
      if (this.vsetCode === appStrings.VALUESETTYPE.ASSET_LOCATION) {
        (this.faLocation.text = null), (this.faLocation.value = null);
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LOCATION) {
        this.hrLocation.text = null;
        this.hrLocation.value = null;
      }
    },
    clearUser() {
      this.hrLocation.value = null;
      this.hrLocation.text = null;
      this.faLocation.text = null;
      this.faLocation.value = null;
      this.totalRows = null;
      this.currentPage = 1;
      this.faLocationData = null;
      this.editMode = false;
    },
    truncateComment(souce, size) {
      return commonHelper.truncate(souce, size);
    },
    rowSelected(item) {
      this.rackRowData = item;
      this.showHideRackModal(true);
    },
    showHideRackModal(flag) {
      this.showRackModal = flag;
    },
  
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
