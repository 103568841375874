import appStrings from '@/app/utility/string.utility';
import Address from '../../../../../components/admin/address'
import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';



export default {
    name: 'addHrLocation',
    components:{appStrings,Address,DatePicker,commonHelper},
    props:['hrData'],
    data(){
        return{
            Resdata:null,
            loader: false,
            editMode:false,
            showValueSetModal:false,
            showAddAddress: false,
            showAlert:false,
            isSuccess:false,
            responseMsg:false,
            organizationForm: {
                org_type: {
                  value: 'LOC',
                  text: 'Location'
                },
                selectedAddressType: {
                  value: null,
                  text: null
                },
                slectedOrgParentName: {
                  value: null,
                  text: null
                },
                org_name: '',
                org_description: null,
                org_short_name: '',
                address_name: '',
                address: '',
                address_id: 0,
                org_id: 0,
                org_parent_id: 0,
                locationType:{
                  value: "T",
                  text: "Temporary"
                },
                nature:{
                  value: null,
                  text: null
                },
                lob:{
                  value: null,
                  text: null
                },
                inactiveDate:null,
              },
        }
    },
    mounted(){
      this.unsubscribe = this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'shared/setActionName') {
          const actionName = state.shared.actionName;
          if (actionName === 'save' ) {
            if (!this.showAddAddress) {
              this.addEditOrganization();
            }
          }
        }
      });
    },
    methods: {
        openValueSetModal(vsetCode) {
            this.vsetCode = vsetCode;
            this.showValueSetModal = true;
            this.setTimeVsetCode = setTimeout(() => {
              const vSetData = {
                valueSetName: vsetCode,
                multiFlag: null
              };
              this.eventBus.$emit('valueSetCode', vSetData);
            }, 0);
          },
          selectedvalueSet(item) {
            if(this.vsetCode===appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST){
              this.organizationForm.slectedOrgParentName.text= item.value_code,
              this.organizationForm.slectedOrgParentName.value=item.value_set_dtl_id;
          } else if(this.vsetCode===appStrings.VALUESETTYPE.FA_LOCATION_TYPE){
            this.organizationForm.locationType.text= item.value_meaning,
            this.organizationForm.locationType.value=item.value_code;
          }
          else if(this.vsetCode===appStrings.VALUESETTYPE.FA_LOCATION_NATURE_VSET){
            this.organizationForm.nature.text= item.value_meaning,
            this.organizationForm.nature.value=item.value_code;
          }
          else if(this.vsetCode===appStrings.VALUESETTYPE.FA_LOB_VSET){
            this.organizationForm.lob.text= item.value_meaning,
            this.organizationForm.lob.value=item.value_code;
          }
        },
          closeValueSetModal() {
            this.showValueSetModal = false;
          },
          resetAddressModal(flag) {
            this.showAddAddress = flag;
          },
          getAddressObj(address) {
            if (address) {
              this.showAddAddress = false;
              this.organizationForm.address = address.address_line;
              this.organizationForm.address_id = address.address_id;
              this.organizationForm.selectedAddressType.value =
                address.address_type.value;
              this.organizationForm.selectedAddressType.text =
                address.address_type.text;
              this.organizationForm.address_name = address.address_name;
            }
          },
          openAddressModal() {
            this.showAddAddress = true;
            setTimeout(() => {
              this.eventBus.$emit(
                'orgAddressType',
                this.organizationForm.selectedAddressType
              );
            }, 0);
          },
          addEditOrganization() {
            const payload = {
              address_id: this.organizationForm.address_id,
              address:this.organizationForm.address,
              erp_org_id: 0,
              org_id: this.organizationForm.org_id,
              org_name: this.organizationForm.org_name,
              org_parent_id: this.organizationForm.slectedOrgParentName.value,
              org_short_name: this.organizationForm.org_short_name,
              org_type_lookup: this.organizationForm.org_type.value,
              inactive_date:this.organizationForm.inactiveDate,
              lob_vset:this.organizationForm.lob.value,
              location_nature_vset:this.organizationForm.nature.value,
              location_type:this.organizationForm.locationType.value,
              org_description:this.organizationForm.org_description
            };
            this.loader = true;
            this.$store
              .dispatch('organization/addEditOrganization', payload)
              .then(response => {
                this.loader = false;
                this.showAlert = true;
                if (response.status === 201) {
                  this.showReceiptPreview = false;
                  this.editMode = false;
                  this.isSuccess = true;
                  this.responseMsg = response.data.message;
                  this.Resdata=response.data.data;
                  this.getNewHrData(this.Resdata)

                } else {
                  this.isSuccess = false;
                  this.responseMsg = response.response.data.message;
                }
              })
              .catch(() => {
                this.loader = false;
                this.showAlert = true;
                this.isSuccess = false;
                this.responseMsg = appStrings.autoFailedMsg;
              });
          },
          getNewHrData(item){
              for(let k in item){
                for(let key in this.hrData){
                  if(k===key){
                    this.hrData[key]=item[k]
                  }
                  else if(k==="org_name"){
                    this.hrData.location.text=item.org_name
                    this.hrData.location.value=item.org_id
                  }
                  else if(k==="lob_vset"){
                    this.hrData.lob.value=item.lob_vset
                    this.hrData.lob.text=item.lob_meaning
                  }
                  else if(k==="location_nature_vset"){
                    this.hrData.nature.value=item.location_nature_vset
                    this.hrData.nature.text=item.loc_nature_meaning
                  }
                }
              }
          }
      
    },
    beforeDestroy() {
      this.unsubscribe();
    }
}